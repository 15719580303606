.logo {
  font-family: 'Quicksand', sans-serif;
  font-weight: 800;
}

.video {
  max-width: 1024px;
  margin: auto;
  background-color: whitesmoke;
}

.video.watched {
  background-color: darkgrey;
}

.youtube-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.youtube {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.country-icon {
  height: 36px;
}